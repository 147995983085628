const fetchAvaliableDocumentations = () => new Promise((resolve, reject) => {
  fetch('./docs-sources.json', {
    accept: 'application/json'
  })
    .then(res => res.json())
    .then(avaliableDocs => {
      const docsWithUrl = avaliableDocs.filter(({ url }) => !!url);
      resolve(docsWithUrl);
    })
    .catch(err => reject(err))
});

export default {
  fetchAvaliableDocumentations
};