
import React from 'react';

import './DocumentationLink.css';

const DocumentationLink = props => {
    const {
      meta,
      onLinkClick
    } = props;

    function handleClick() {
      onLinkClick(meta);
    }

  return (  
    <div className="documentation-link" onClick={handleClick}>
      {meta.name}
    </div>
  )
}

export default DocumentationLink;