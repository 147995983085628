import React from 'react';

import AsyncApi from '@kyma-project/asyncapi-react';
import '@kyma-project/asyncapi-react/lib/styles/fiori.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import './AsyncApiUI.css';
import './icons';

const defaultConfig = {
  show: {
    info: true,
    servers: true,
    security: false,
    messages: true,
    schemas: true,
    channels: true,
  },
  showErrors: true,
  disableDefaultTheme: false
};

class AsyncApiUI extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSchemaFetching: true
    };
  }

  componentDidMount() {
    this.fetchAsyncApiSchema();
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;

    if (url !== prevProps.url) {
      this.fetchAsyncApiSchema();
    }
  }

  async fetchAsyncApiSchema() {
    const { url } = this.props;

    try {
      this.setState({
        isSchemaFetching: true
      })

      const schema = await fetch(url)
        .then(response => {
          switch (response.status) {
            case 200: {
              return response.text();
            }
            case 204: {
              throw new Error('No Content');   
            }
            case 404: {
              throw new Error('Not Found');   
            }
            default: {
              throw new Error(`Status code: ${response.status}`)
            }
          }
        });
      
      this.setState({
        schema,
        isSchemaFetching: false
      });
    } catch (err) {
      this.setState({
        schemaFetchError: new Error(`Failed to load API definition from ${url} (${err.message})`),
        isSchemaFetching: false
      });
    }
  }
  
  render() {
    const {
      isSchemaFetching,
      schema,
      schemaFetchError
    } = this.state;

    if (isSchemaFetching) {
      return 'Loading...'
    }
    
    return schemaFetchError
      ? schemaFetchError.message
      : <AsyncApi schema={schema} config={defaultConfig} />;
  }
}

export default AsyncApiUI;
