import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { DocumentationType } from './enums';

import Sidebar from './components/Sidebar/Sidebar';
import AsyncApiUI from './components/AsyncApiUI/AsyncApiUI'

import documentationApi from './api/documentation';

import './App.css';

class App extends React.PureComponent {
  documentationList = [];

  constructor(props) {
    super(props);

    this.state = {
      isDocsFetching: true
    };

    this.onDocumentationLinkClick = this.onDocumentationLinkClick.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      this.documentationList = await documentationApi.fetchAvaliableDocumentations();

      this.setState({
        selectedDocumentation: this.documentationList[0],
        isDocsFetching: false
      });
    } catch (err) {
      this.setState({
        docsFetchError: new Error(`Failed to load API definitions (${err.message})`),
        isDocsFetching: false
      });
    }
  }

  onDocumentationLinkClick(selectedDocumentation) {
    this.setState(({ selectedDocumentation }))
  }

  renderDocumentation({ type, name, url }) {
    switch(type) {
      case DocumentationType.SWAGGER: {
        return <SwaggerUI url={url} />
      }
      case DocumentationType.ASYNC_API: {
        return <AsyncApiUI url={url} />
      }
      default: {
        return `Could not recognize ${name} documentation type: ${type || null}.`;
      }
    }
  }
  
  render() {
    const {
      isDocsFetching,
      docsFetchError,
      selectedDocumentation
    } = this.state;

    if (isDocsFetching) {
      return 'Loading...';
    }

    return (
      <div className="App">
         <Sidebar
          documentationList={this.documentationList}
          onDocumentationLinkClick={this.onDocumentationLinkClick}
         />
        <div id="docs-content">
          {
            docsFetchError
              ? docsFetchError.message
              : this.renderDocumentation(selectedDocumentation)
          }
        </div>
      </div>
    );
  }
}

export default App;
