import React from 'react';

import DocumentationLink from '../DocumentationLink/DocumentationLink';

import './Sidebar.css';

const Sidebar = (props) => {
  const {
    documentationList,
    onDocumentationLinkClick
  } = props;
  return (
    <div className="side-bar">
        <div className="side-bar-header">
            <h1>Essentim</h1>
        </div>
        <div className="side-bar-body">
            <h3>API DOCS</h3>
            {
              documentationList.map(documentationEntry => (
                <DocumentationLink
                  key={documentationEntry.url}
                  meta={documentationEntry}
                  onLinkClick={onDocumentationLinkClick}
                />
              ))
            }
        </div>
    </div>
  )
}

export default Sidebar;